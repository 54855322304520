import 'amfe-flexible'; // 移动适配方案，自动计算html的 font-size 字体的大小
import Vue from 'vue';
import App from './App';
import { router } from './router';

import './assets/css/style.css';

new Vue({
	router,
	el: '#app',
	render: h => h(App)
});
