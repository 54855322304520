import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// 英文页面路由
const enRoutes = [
	{
		name: 'login_en',
		component: () => import('./view/process/en/login'),
		meta: {
			title: '登陆'
		}
	},
	{
		name: 'realnameAuth_en',
		component: () => import('./view/process/en/realnameAuth'),
		meta: {
			title: '实名认证'
		}
	},
	{
		name: 'industrySelect_en',
		component: () => import('./view/process/en/industrySelect'),
		meta: {
			title: '行业选择'
		}
	},
	{
		name: 'question_en',
		component: () => import('./view/process/en/question'),
		meta: {
			title: '问卷调查'
		}
	},
	{
		name: 'shootCard_en',
		component: () => import('./view/process/en/shootCard'),
		meta: {
			title: '名片拍摄'
		}
	},
	{
		name: 'information_en',
		component: () => import('./view/process/en/information'),
		meta: {
			title: '个人信息'
		}
	},
	{
		name: 'pay_en',
		component: () => import('./view/process/en/pay'),
		meta: {
			title: '个人信息'
		}
	},
	{
		name: 'memberCode_en',
		component: () => import('./view/process/en/memberCode'),
		meta: {
			title: '入场凭证'
		}
	},
	{
		name: 'waiting_en',
		component: () => import('./view/process/en/waiting'),
		meta: {
			title: '等待审核'
		}
	}
];

// 中文页面路由
const znRoutes = [
	{
		name: 'login',
		component: () => import('./view/process/zh/login'),
		meta: {
			title: '登陆'
		}
	},
	{
		name: 'realnameAuth',
		component: () => import('./view/process/zh/realnameAuth'),
		meta: {
			title: '实名认证'
		}
	},
	{
		name: 'industrySelect',
		component: () => import('./view/process/zh/industrySelect'),
		meta: {
			title: '行业选择'
		}
	},
	{
		name: 'question',
		component: () => import('./view/process/zh/question'),
		meta: {
			title: '问卷调查'
		}
	},
	{
		name: 'shootCard',
		component: () => import('./view/process/zh/shootCard'),
		meta: {
			title: '名片拍摄'
		}
	},
	{
		name: 'information',
		component: () => import('./view/process/zh/information'),
		meta: {
			title: '个人信息'
		}
	},
	{
		name: 'officialAccount',
		component: () => import('./view/process/zh/officialAccount'),
		meta: {
			title: '关注公众号'
		}
	},
	{
		name: 'memberCode',
		component: () => import('./view/process/zh/memberCode'),
		meta: {
			title: '入场凭证'
		}
	},
	{
		name: 'waiting',
		component: () => import('./view/process/zh/waiting'),
		meta: {
			title: '等待审核'
		}
	}
];

const routes = [
	{
		name: 'auth',
		component: () => import('./view/auth'),
		meta: {
			title: '微信授权'
		}
	},
	{
		name: 'errorTips',
		component: () => import('./view/errorTips'),
		meta: {
			title: '错误提示'
		}
	},
	{
		name: '404',
		component: () => import('./view/404'),
		meta: {
			title: '页面不存在'
		}
	},
	{
		name: 'showPoster',
		component: () => import('./view/poster/showPoster'),
		meta: {
			title: '海报'
		}
	},
	{
		name: 'redirect',
		component: () => import('./view/redirect'),
		meta: {
			title: '海报'
		}
	},
	{
		name: 'check',
		component: () => import('./view/check/index'),
		meta: {
			title: '现场查验系统 - SZFA'
		}
	},
	{
		name: 'info',
		component: () => import('./view/check/info'),
		meta: {
			title: '现场查验 - SZFA'
		}
	},
	...znRoutes,
	...enRoutes,
	{
    path: '*',
    redirect: {
      name: 'auth',
    }
  },
];

// 给路由添加访问路径
routes.map(route => {
	route.name && (route.path = '/' + route.name)
});

const router = new Router({
	// mode: 'history',
	routes
});

router.beforeEach((to, from, next) => {
	// const title = to.meta && to.meta.title;
	// if (title) {
	//   document.title = title;
	// }

	next();
});

export {
	router
};
